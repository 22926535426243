<template>
  <div class="home_bottom">
    <div class="bg">
      <div class="div_country">
        <div class="title">Ámerica Latina</div>
        <div class="text2">
          <img class="img_icon" src="@/assets/images/img_threeicon_transparentbg1.png" alt="">
          <br>6.000 millones de valor de mercado del comercio electrónico
        </div>
        <div class="text2">
          <img class="img_icon" src="@/assets/images/img_threeicon_transparentbg2.png" alt="">
          <br>95% de penetración de internet
        </div>
        <div class="text2">
          <img class="img_icon" src="@/assets/images/img_threeicon_transparentbg3.png" alt="">
          <br>44% de crecimiento en comercio electrónico BSC
        </div>
      </div>
      <div class="div_country div_country2">
        <div class="title">Brasil</div>
        <div class="text2">
          <img class="img_icon" src="@/assets/images/img_threeicon_whitebg1.png" alt="">
          <br>22.600 millones de valor de mercado del comercio electrónico
        </div>
        <div class="text2">
          <img class="img_icon" src="@/assets/images/img_threeicon_whitebg2.png" alt="">
          <br>70% de penetración de internet
        </div>
        <div class="text2">
          <img class="img_icon" src="@/assets/images/img_threeicon_whitebg3.png" alt="">
          <br>28.3% de crecimiento en comercio electrónico BSC
        </div>
      </div>
      <div class="div_country div_country3">
        <div class="title">México</div>
        <div class="text2">
          <img class="img_icon" src="@/assets/images/img_threeicon_whitebg1.png" alt="">
          <br>5.400 millones de valor de mercado del comercio electrónico
        </div>
        <div class="text2">
          <img class="img_icon" src="@/assets/images/img_threeicon_whitebg2.png" alt="">
          <br>280 millones de transacciones en linea
        </div>
        <div class="text2">
          <img class="img_icon" src="@/assets/images/img_threeicon_whitebg3.png" alt="">
          <br>2% de participación del comercio electronico B2C en el PIB
        </div>
      </div>
      <div class="div_country">
        <div class="title">Chile</div>
        <div class="text2">
          <img class="img_icon" src="@/assets/images/img_threeicon_transparentbg1.png" alt="">
          <br>6.000 millones de valor de mercado del comercio electrónico
        </div>
        <div class="text2">
          <img class="img_icon" src="@/assets/images/img_threeicon_transparentbg2.png" alt="">
          <br>95% de penetración de internet
        </div>
        <div class="text2">
          <img class="img_icon" src="@/assets/images/img_threeicon_transparentbg3.png" alt="">
          <br>44% de crecimiento en comercio electrónico BSC
        </div>
      </div>
      <div class="div_country div_country2">
        <div class="title">Perú</div>
        <div class="text2">
          <img class="img_icon" src="@/assets/images/img_threeicon_whitebg1.png" alt="">
          <br>Valor de mercado del comercio electrónico 48h
        </div>
        <div class="text2">
          <img class="img_icon" src="@/assets/images/img_threeicon_whitebg2.png" alt="">
          <br>60% de penetración de internet
        </div>
        <div class="text2">
          <img class="img_icon" src="@/assets/images/img_threeicon_whitebg3.png" alt="">
          <br>52% de crecimiento en comercio electrónico BSC
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.home_bottom {
  min-width: 1400px;
  width: 100%;
  .bg {
    background: #FFFFFF;
  }
  .div_country {
    padding: 0 180px;
    height: 400px;
    text-align: center;
    background: #3D2158;
    color: white;
    align-items: center;
    display: flex;
    justify-content: space-between;
    .title {
      text-align: left;
      font-size: 30px;
      color: white;
      margin-right: 50px;
      width: 250px;
    }
    .img_icon {
      height: 65px;
      margin-bottom: 30px;
    }
    .text2 {
      // width: 200px;
      padding: 0 15px;
      height: 150px;
      text-align: center;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .div_country2 {
    background: white;
    color: #724CC6;
    .title {
      color: #333333;
    }
  }
  .div_country3 {
    background: #F5F5F5;
    color: #333333;
    .title {
      color: #333333;
    }
  }
}
</style>
